import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['name', 'room', 'token'];

  async start(event) {
    event.preventDefault();
    let name = this.nameTarget.value;
    let room = this.roomTarget.value;
    const url = "https://cornsilk-pug-8334.twil.io/video-token";

    const tokenTarget = this.tokenTarget;
    try {
      const response = await fetch(url, {
        method: 'post',
        body: JSON.stringify({indentity: name}),
        headers: {
          "Content-Type": "application/json"
        },
      })
      
      try {
        const tokenResponse = await response.json();
        tokenTarget.textContent = tokenResponse.token;
      } catch(err) {
        console.log("Failed to convert token to JSON", err)
      }

    } catch(err) {
      console.log("Can’t access " + url + " response. Error:", err);
    }
  }
}
